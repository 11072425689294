<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <div class="d-flex flex-row align-items-center mb-3" style="gap: 0px">
            <BackButton />
            <h1 class="m-0 text-light">
                {{ t('pages.more.invoices.invoices') }}
            </h1>
        </div>
        <ul class="list-group shadow-sm">
            <li class="list-group-item p-3">
                <p>
                    {{ t('pages.more.invoices.explanation') }}
                </p>
                <span class="text-muted">
                    {{ t('pages.more.invoices.questions') }}
                    <router-link
                        style="font-weight: 500"
                        to="/more/contact-support"
                        >{{ t('pages.more.invoices.contactUs') }}</router-link
                    >.
                </span>
            </li>
            <li
                class="list-group-item text-center py-4"
                v-if="!user || !user.invoices || !user.invoices.length"
            >
                {{ t('pages.more.invoices.noneFound') }}
            </li>
            <a
                :href="GLOBAL_VAR_BACKEND_STORAGE_URL + invoice.filename"
                v-for="invoice in user.invoices"
                :key="invoice.id"
                class="list-group-item d-flex flex-row align-items-center justify-content-between tap-react"
                style="font-weight: 500"
            >
                <div class="d-flex align-items-center" style="gap: 0.5rem">
                    <i class="fa fa-file-invoice"></i>
                    {{ t('pages.more.invoices.invoice') }} {{ invoice.name }}
                </div>
                <i class="fa fa-chevron-right text-muted"></i>
            </a>
        </ul>
    </div>
</template>

<script setup>
import { inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import BackButton from '@/components/BackButton';
import { useUserStore } from '@/stores/user-store';
import { storeToRefs } from 'pinia';
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
const GLOBAL_VAR_BACKEND_STORAGE_URL = inject('GLOBAL_VAR_BACKEND_STORAGE_URL');
const { t } = useI18n();

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

onMounted(() => {
    userStore.fetchInvoices();
});
</script>
